import axios from '@axios'

export default function suggestionsServices() {
  const getSuggestionsValid = async (queryParams) => {
    const api = await axios.get('/suggestions/valid', queryParams)
    return api.data.response.suggestions
  }

  const createSuggestion = async (queryParams) => {
    const api = await axios.post('/suggestions', queryParams)
    return api
  }

  return {
    getSuggestionsValid,
    createSuggestion,
  }
}
